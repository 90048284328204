var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { MessageFromPopup } from '@wix/cashier-payments-app/dist/src/types/PremiumPopup';
import { getPremiumStatus, makeRequestForOrder } from '../api';
import { APP_ID, MODAL_SIZE } from '../constants';
import { buttonRole } from '../editor/structures/button';
import { isPreview } from '../utils/states';
import { getNoPaymentsPopupUrl, getUpgradeAndTestPaymentModel } from '../utils/links';
import { tryToGetOrigin } from '../utils/getOrigin';
import { getBiLogger } from '../utils/biLogger';
import { defaultSentryConfig } from '../utils/sentry';
import { createHandleError, defaultErrorHandler } from '../utils/handleError';
import { ViewerInteractionName } from '../enums/ViewerInteractionName';
export default {
    initAppForPage: function () {
        return Promise.resolve({});
    },
    createControllers: function (controllerConfigs) {
        return controllerConfigs.map(function (controller) { return Promise.resolve({
            pageReady: function (_, wixCode) {
                var handleError = defaultErrorHandler;
                var fedOpsLogger;
                try {
                    var appId = controller.appParams.appDefinitionId;
                    var widgetId = controller.type;
                    var fedOpsLoggerFactory = controller.platformAPIs.fedOpsLoggerFactory;
                    fedOpsLogger = fedOpsLoggerFactory.getLoggerForWidget({ appId: appId, widgetId: widgetId });
                    fedOpsLogger.interactionStarted(ViewerInteractionName.ALE);
                }
                catch (e) {
                    handleError(e);
                }
                try {
                    var tags_1 = {
                        msId: controller.platformAPIs.bi.metaSiteId,
                        userId: controller.platformAPIs.bi.siteMemberId || controller.platformAPIs.bi.visitorId,
                        locale: controller.wixCodeApi.window.locale,
                    };
                    var sentryInstance = controller.platformAPIs.monitoring.createMonitor(defaultSentryConfig.dsn, function (config) { return (__assign(__assign(__assign({}, config), defaultSentryConfig.config), { tags: __assign({}, tags_1) })); });
                    handleError = createHandleError(sentryInstance.captureException.bind(sentryInstance));
                }
                catch (e) {
                    handleError(e, 'Sentry is not initialized');
                }
                try {
                    var instance_1 = wixCode.site.getAppToken(APP_ID);
                    var _a = controller.config, productId_1 = _a.productId, termsAndConditionsLink = _a.termsAndConditionsLink;
                    var metaSiteId = controller.platformAPIs.bi.metaSiteId;
                    var webBiLogger = controller.platformAPIs.biLoggerFactory();
                    var biLogger_1 = getBiLogger({
                        appid: APP_ID,
                        appInstanceId: controller.appParams.appInstanceId,
                        memberId: controller.platformAPIs.bi.siteMemberId,
                        msid: metaSiteId,
                        origin: controller.wixCodeApi.location.url,
                        paybuttonid: controller.appParams.instanceId,
                        sessionId: controller.platformAPIs.bi.viewerSessionId,
                        visitorId: controller.platformAPIs.bi.visitorId,
                    }, webBiLogger);
                    var origin_1 = tryToGetOrigin(wixCode);
                    var button = controller.$w("@" + buttonRole);
                    var paymentOptions_1 = Boolean(termsAndConditionsLink) ? { termsAndConditionsLink: termsAndConditionsLink } : {};
                    var viewMode_1 = wixCode.window.viewMode;
                    var payAppBiParams_1 = {
                        sessionId: controller.platformAPIs.bi.viewerSessionId,
                        pbId: controller.appParams.instanceId,
                        pbOrigin: controller.wixCodeApi.location.url,
                        siteMemberId: controller.platformAPIs.bi.siteMemberId,
                    };
                    var startPayment_1 = function (orderId) {
                        return wixCode.pay.startPayment(orderId, __assign(__assign({}, paymentOptions_1), payAppBiParams_1));
                    };
                    var deviceType_1 = controller.wixCodeApi.window.formFactor;
                    var openUpgradeAndPlaceTestModal_1 = function (orderId) { return __awaiter(void 0, void 0, void 0, function () {
                        var event;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, wixCode.window.openModal(getUpgradeAndTestPaymentModel(instance_1), {
                                        width: MODAL_SIZE[deviceType_1].width,
                                        height: MODAL_SIZE[deviceType_1].height,
                                        theme: 'BARE',
                                    })];
                                case 1:
                                    event = _a.sent();
                                    if (event && event.message === MessageFromPopup.PlaceTestOrder) {
                                        return [2 /*return*/, startPayment_1(orderId)];
                                    }
                                    return [2 /*return*/];
                            }
                        });
                    }); };
                    var openNoPaymentsPopup_1 = function () {
                        return wixCode.window.openModal(getNoPaymentsPopupUrl({ instance: instance_1, referralInfo: 'payButton', startLoadTime: Date.now() }), {
                            width: MODAL_SIZE[deviceType_1].width,
                            height: MODAL_SIZE[deviceType_1].height,
                            theme: 'BARE',
                        });
                    };
                    var onButtonClick_1 = function () { return __awaiter(void 0, void 0, void 0, function () {
                        var orderId, isPremium, _a;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    biLogger_1.uouPayButtonClick({});
                                    return [4 /*yield*/, makeRequestForOrder(instance_1, productId_1, origin_1)];
                                case 1:
                                    orderId = (_b.sent()).orderId;
                                    if (!isPreview(viewMode_1)) return [3 /*break*/, 3];
                                    return [4 /*yield*/, getPremiumStatus(instance_1)];
                                case 2:
                                    _a = _b.sent();
                                    return [3 /*break*/, 5];
                                case 3: return [4 /*yield*/, getPremiumStatus(instance_1, origin_1)];
                                case 4:
                                    _a = _b.sent();
                                    _b.label = 5;
                                case 5:
                                    isPremium = _a;
                                    if (isPremium) {
                                        fedOpsLogger.interactionStarted(ViewerInteractionName.PAY_BUTTON_OPEN_PAY_APP);
                                        return [2 /*return*/, startPayment_1(orderId)];
                                    }
                                    if (!isPremium && isPreview(viewMode_1)) {
                                        fedOpsLogger.interactionStarted(ViewerInteractionName.OPEN_PREMIUM_POPUP);
                                        return [2 /*return*/, openUpgradeAndPlaceTestModal_1(orderId)];
                                    }
                                    if (!isPremium) {
                                        fedOpsLogger.interactionStarted(ViewerInteractionName.OPEN_NO_PAYMENTS_POPUP);
                                        return [2 /*return*/, openNoPaymentsPopup_1()];
                                    }
                                    return [2 /*return*/];
                            }
                        });
                    }); };
                    button.onClick(function () { return __awaiter(void 0, void 0, void 0, function () {
                        var error_1;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 2, , 3]);
                                    return [4 /*yield*/, onButtonClick_1()];
                                case 1: return [2 /*return*/, _a.sent()];
                                case 2:
                                    error_1 = _a.sent();
                                    handleError(error_1);
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); });
                    fedOpsLogger.interactionEnded(ViewerInteractionName.ALE);
                }
                catch (error) {
                    handleError(error);
                }
            },
            exports: function () { return ({}); },
        }); });
    }
};
